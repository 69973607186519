import { GatsbySeo } from 'gatsby-plugin-next-seo'

const ContactPage = () => {
  return (
    <>
      <GatsbySeo title="Contact Us" />

      <section className="section is-small">
        <div className="container">
          <div className="has-width-large content margin-auto">
            <div className="has-width-large margin-auto mb-6">
              <h2 className="title is-4 is-spaced has-text-centered has-text-midnightBlue">
                Get help fast from our Help Centre
              </h2>

              <p className="subtitle is-6">
                If you have a query about testing, we've almost certainly answered it in our Help Centre so this should
                be your first port of call.
              </p>

              <p className="subtitle is-6">
                Our Help Centre will also help you quickly if you have a problem / query with a test you've already
                purchased. Using the Help Center allows us to prioritise existing customers.
              </p>

              <div className="buttons is-centered mb-6">
                <a
                  className="button is-midBlue"
                  href="https://helpcentre.c19testing.co.uk"
                  target="_blank"
                  rel="noreferrer"
                >
                  Go to the Help Centre
                </a>
              </div>
              <p>
                If our help centre doesn't answer your question you can also submit a query directly to our customer
                support team.
              </p>
              <div className="buttons is-centered">
                <a
                  href="https://helpcentre.c19testing.co.uk/hc/en-gb/requests/new"
                  target="_blank"
                  rel="noreferrer"
                  className="button is-midBlue"
                >
                  Contact our customer support team
                </a>
              </div>

              <p className="mt-4">Please note</p>
              <ul>
                <li>
                  Submitting a query via our help centre allows us to send your request to the correct team which gets
                  you the answers you want faster.
                </li>
                <li>We do not have a manned phone line.</li>
                <li>We send results 24/7.</li>
                <li>
                  Our core business hours for general enquiries are from <b>9am-5pm, Monday to Sunday</b>.
                </li>
                <li>
                  We can respond to urgent enquiries from existing customers from <b>8am to 8pm</b>, 7 days a week.
                  Between 8pm and 8am we have no staff to respond to queries.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactPage
